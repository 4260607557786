import * as React from "react";
import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import "./App.css";
import AppProvider from "context/AppProvider";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import ErrorBoundaryDefaultFallback from "components/errorBoundary/ErrorBoundaryDefaultFallback";
import useCheatCode from "hooks/useCheatCode";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

const AppContent = React.lazy(() => import("./AppContent"));

const hotJarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION);

function App() {
  useCheatCode();
  useEffect(() => {
    Hotjar.init(hotJarSiteId, hotjarVersion, {
      debug: process.env.NODE_ENV === "development",
    });
  }, []);

  const { version } = packageInfo;

  const isCacheBusterEnabled =
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "staging" ||
    process.env.NODE_ENV === "demo";

  return (
    // TODO - add fallback
    <CacheBuster
      currentVersion={version}
      isEnabled={isCacheBusterEnabled}
      isVerboseMode={false}
    >
      <React.Suspense fallback={null}>
        <AppProvider>
          <ErrorBoundary
            componentName={App.name}
            fallback={<ErrorBoundaryDefaultFallback />}
          >
            <AppContent />
          </ErrorBoundary>
        </AppProvider>
      </React.Suspense>
    </CacheBuster>
  );
}

export default App;
